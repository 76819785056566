@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

html, body, #root, .App, #length, .full-height {
  margin: 0;
  padding: 0;
  height: 100%;
}
.dashboard-plant-container{
  height: 90% !important;
}
.header_container
{
    background-color: #0A070D;
    color: #fff;
    z-index: 3;
    height: 47px;
}
.brand_logo img
{
    height: 25px;
}
.selection_type
{
    font-variant-caps: all-petite-caps;
    text-align: center;
}
.selection_type h4
{
    line-height: initial;
}

.h_time
{
    font-weight: 400;
    text-align: end;
}

.h_time label
{
    background-color: #fff;
    color: #0A070D;
    padding: 0 5px 0 5px;
}

.sidebar {
    height: 100%;
    width: 80px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3E3D3D;
    overflow-x: hidden;
    padding: 0;
  }

.menu_items
{
    background-color: #0A070D;
}
.menu_items ul
    {
        margin-top: 50px;
        padding: inherit;

    }
.menu_items ul li
    {
       font-size: 12px;
        list-style-type: none;
        border-bottom: 1px solid #3E3D3D;
        text-align: center;
        color: #CCCCCC;
        padding: 15px 0 15px 0;
    }

    .menu_items li>a
    {
        text-decoration: none;
        display: grid;
        color: #CCCCCC;
        opacity: 0.5;
    }
    .menu_items li>a:hover
    {
        text-decoration: none;
        display: grid;
        color: #CCCCCC;
        opacity: 1;
        margin: 0;
    }
    .menu_items li>a>img
    {
        margin: 0 0 0 28px;
    }

    .menu_items li>img
    {
        margin: 0 0 0 28px;
        display: flex;
    }


    /* Main */
    .main
    {
        color: #000;
    left: 50px;
    top: 30px;
    width: calc(100% - 56px);
    position: absolute;
    background-color: #fff;
    padding: 0 ;
    margin: 0;
    }

    .eng_info
    {
        border: 1px solid #000;
        display: flex;
        text-align: center;
        overflow: auto;
    }

    .eng_value
    {
        border-right: 1px solid #E3E1E1;
        padding: 0 12px;
        align-items: center;
        display: inline-grid;
    }
  .eng_value h6
  {
    color:#5D666F;
    font-size: .80rem;
    margin: 7px 0;
    line-height: 20px;
  }
  .eng_value label
  {
    font-weight:700;
    font-size: 1.2rem;
  }

  .eng_value_align
  {
    display: grid;
    align-items: center;
    vertical-align: middle;
    align-content: center;
  }

  .kpi_value
  {
    color: #000;
    font-weight:700;
  }

   .kpi-state
   {
    flex: 0;
    padding: 0 0;
    }
    .kpi-state h6{
        -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          padding: 23px 0;
        }
.eng_range
{
    color: #FF0000;

}

.pl_oper h6
{
    color: #0E5878;
}

.plant-container
{
    border: 1px solid #000;
    padding: 15px;
}

.plant-wrapper label
{
    font-size: .85rem;
    font-weight: 500;
    color: #0E5878;
    margin: 0  ;

}

.plant-wrapper span{
    font-size: .85rem;
    background-color: #fff;
    border: 1px solid #000;
    padding: 0;
    margin: 0 ;
    float: right;
    text-align: center;
    padding: 0 10px;

}

.inverter-status
{
    border: 1px solid #000;
    color: #0E5878;
    margin-right: 10px;
    padding: 0 14px;
}

.is-status
{
    text-align: center;
}
.is-status label
{
    padding: 10px 30px;
    background-color: #00FF00;
    border: 1px solid #000;
}

.is-margin
{
    margin: 0;
}

.ipaddress_wrap
{
    border: 1px solid #000;
    margin: 0 auto;
    border-radius: 4px;
    padding: 20px 0 0;
}

.ip-table tbody, tr
{
    border-style: hidden;
    color: #0E5878;
    text-transform: uppercase;

}

.ip-table thead>tr
{
    border-style: hidden;
    color: #000;
}
.font-weight
{
    font-weight: 600;
}

.ipnode
{
    position: absolute;
    text-align: left;
    top: 166px;
    left: 430px;
    background-color: #fff;
    padding: 10px;
    text-transform: uppercase;
}

.temp-container
{
    background-color: #598ECC;
    border: 1px solid #000;
    color: #fff;
}

.temp-container h5{
    background-color:#216BD0 ;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    padding: 10px;
}

.irr-container
{
    background-color: #2775B4;
    border: 1px solid #000;
    color: #fff;
}
.irr-container h5{
    background-color:#0C6395 ;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    padding: 10px;
}
.other-container
{
    background-color: #A66DD0;
    border: 1px solid #000;
    color: #fff;
}
.other-container h5{
    background-color:#7707B0 ;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    padding: 10px;
}

#background_logo {
  background-image: url('../public/assets/background_logo.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #cccccc;
}

#plant-table td {
  width: 150px !important;
  font-size: 14px !important;
}

.smb-input{
  max-width: 80px;
  background-color: #00FF00;
  text-align: center;
}

.smb-input-red{
  max-width: 80px;
  background-color: #ff0000;
  text-align: center;
}

.smb-total-input{
  max-width: 80px;
  text-align: center;
}

.report-table tbody, tr{
  border-style: solid;
  font-size: 14px;
  color: black;
  text-transform: none;
}

.plant-link {
  text-decoration: none;
  color: #000;
  max-width: 300px;
}
.plant-link label {
  color: grey;
}

/* Admin Dashboard */
#main-body {
  display: flex;
  font-family: 'Josefin Slab', sans-serif;
  background-color: rgba(209, 214, 197, 0.35);
  font-size: 16px;
}

#FullPannel {
  display: flex;
  flex-direction: row;
  height: 100vh !important;
  width: 100% !important;
  overflow-x: hidden;
}
#LeftScrollableMenus {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 575px !important;
}
#LeftScrollableMenus .menu-container {
  position: absolute;
  inset: 0px;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -15px;
}
#RightPannel {
  width: 100% !important;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
}
#Content {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 8px;
  scrollbar-width: thin;
}
.navbar {
  padding-left: 5rem;
  z-index: 1000;
  transition: padding 250ms;
}
.nav-exp {
  padding-left: 15rem;
  transition: padding 250ms;
}
.navbar-nav .dropdown .dropdown-menu {
  margin-top: 12px !important;
}
#sidenav {
  position: relative;
  min-width: 6rem !important;
  max-width: 15rem !important;
  height: 100% !important;
  color: white;
  z-index:1001;
  scrollbar-width: thin;
  background: rgb(17, 24, 39);
}
#sidenav .logo {
  width: 40px;
  height: 40px;
}
#sidenav-links {
  list-style-type: none;
  padding: 0 !important;
  border-top: 1px solid grey;
}
#sidenav-links a {
  text-decoration: none;
  color: white;
}
#sidenav-links svg {
  color: grey;
}
#sidenav-links .nav-link-text {
  margin-left: 1rem;
}
#sidenav-links .sidenav-item .active {
  font-size: 17px;
  font-weight: 800;
}
 #sidenav-links .sidenav-item .active svg {
   color: white;
 }
.sidenav-expand {
  width: 15rem;
  transition: width 250ms;
}
.sidenav-collapse {
  width: 6rem;
  transition: width 250ms;
}
.sidenav-collapse #sidenav-links span, .sidenav-collapse .branding {
  display: none;
}
.sidenav-collapse .sidenav-link, .sidenav-collapse .branding-area {
  justify-content: center !important;
}

/* Top Navbar custom styles*/
@media (min-width: 992px) {
  .dropdown-menu .dropdown_item-1 {
    transform-origin: top center;
    animation: translateX 300ms 60ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-2 {
    transform-origin: top center;
    animation: translateX 300ms 120ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-3 {
    transform-origin: top center;
    animation: translateX 300ms 180ms ease-in-out forwards;
  }
}

@-moz-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-webkit-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-o-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.message-dropdown {
  margin-right: 50px;
}
.message-dropdown-menu {
  margin-top: 20px !important;
  width: 20rem;
}
.message-dropdown-menu .message-description {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.dropdown-list-image{
  margin-top: -10px;
}
.dropdown-list-image .rounded-circle {
  width: 40px;
  height: 40px;
}
.text-truncate {
  display: block;
  max-width: 220px;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-triangle-excl{
  margin-top: -3px;
}

.table-responsive {
  border-radius: 5px;
  border: 1px solid lightgrey;
}

#login-container {
  margin: 30px auto;
  padding: 40px;
}
#login-area {
  border: 1px solid grey;
  border-radius: 20px;
  margin-left: 8rem;
  margin-right: 8rem;
}
#login-area .login-logo-container {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

#login-area .login-form-container {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.image-container {
  height: 100%;
}
.action-icons {
  cursor: pointer;
}
.report-cards {
  width: 18rem;
}